import React from 'react';

import ContentArea from '../Shared/ContentArea/ContentArea';
import StartPageModel from './Models/StartPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import Grid from '../Shared/Grid/Grid';
import { styled } from '@glitz/react';

function StartPage() {
  const { contentArea, inEditMode } = useCurrentPage<StartPageModel>();

  return (
    <Main>
      <Grid
        type={12}
        data-content-area
        {...applyEditModeAttr(inEditMode && 'MainContent')}
      >
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </Grid>
    </Main>
  );
}

const Main = styled.main({});

export default StartPage;
